import { isClient } from '@shared/utils/is-client'
import * as process from 'process'

export const getConfigProperty: (name: string) => string | undefined = (name) => {
	if (isClient) {
		return window.__env__[name]
	} else {
		return process.env[name]
	}
}

export const isProduction = getConfigProperty('MODE') === 'production'
