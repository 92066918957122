import { useEffect, useMemo, useRef } from 'react'
import { isClient } from '@shared/utils/is-client'

export const useEventListener = (
	eventName: string,
	handler: (e: any) => void,
	element?: HTMLElement
) => {
	const listenElement = useMemo(() => (isClient ? element || document : null), [element])
	// Create a ref that stores handler
	const savedHandler = useRef<any>(null)

	// Update ref.current value if handler changes.
	// This allows our effect below to always get latest handler ...
	// ... without us needing to pass it in effect deps array ...
	// ... and potentially cause effect to re-run every render.
	useEffect(() => {
		savedHandler.current = handler
	}, [handler])

	useEffect(
		() => {
			// Make sure listenElement supports addEventListener
			// On
			const isSupported = listenElement && listenElement.addEventListener
			if (!isSupported) return undefined

			// Create event listener that calls handler function stored in ref
			const eventListener = (event) => savedHandler.current(event)

			// Add event listener
			listenElement.addEventListener(eventName, eventListener)

			// Remove event listener on cleanup
			return () => {
				listenElement.removeEventListener(eventName, eventListener)
			}
		},
		[eventName, listenElement] // Re-run if eventName or element changes
	)
}
