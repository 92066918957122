import React, { PropsWithChildren } from 'react'
import { Transition } from 'react-transition-group'
import { ClickAwayListener } from '@material-ui/core'
import cx from 'classnames'
import Cross from '@shared/svg/cross.svg'

import { IStateProps } from './model'

import styles from './style.module.scss'

export const ModalView = ({
	onCloseModal,
	children,
	size,
	overlayRef,
	onCrossKeyPress,
	show,
}: PropsWithChildren<IStateProps>) => {
	return (
		<Transition mountOnEnter unmountOnExit timeout={{ enter: 0, exit: 300 }} in={show}>
			{(state) => (
				<div className={cx(styles.overlay, { [styles[state]]: state })} ref={overlayRef}>
					<ClickAwayListener onClickAway={onCloseModal}>
						<div
							role='dialog'
							className={cx(styles.contentWrap, {
								[styles[state]]: state,
								[styles.mediumSize]: size === 'medium',
								[styles.largeSize]: size === 'large',
							})}
						>
							<div className={styles.content}>
								<div
									tabIndex={0}
									className={styles.close}
									onKeyPress={onCrossKeyPress}
									onClick={onCloseModal}
								>
									<Cross />
								</div>
								{children}
							</div>
						</div>
					</ClickAwayListener>
				</div>
			)}
		</Transition>
	)
}

export const HeaderView = ({ children }: PropsWithChildren) => (
	<div className={styles.header}>{children}</div>
)

export const BodyView = ({ children }: PropsWithChildren) => (
	<div className={styles.body}>{children}</div>
)
