import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { apiUrls } from '@http/api-urls'
import { httpService } from '@http/service'

export enum USER_DOCUMENT_IDS {
	UNIFIED_PERSONALIZED_CONSENT = 'UNIFIED_PERSONALIZED_CONSENT',
	CREDIT_HISTORY_CONSENT = 'CREDIT_HISTORY_CONSENT',
	ADVERTISING_CONSENT = 'ADVERTISING_CONSENT',
	USER_AGREEMENT = 'USER_AGREEMENT',
}

interface UserDocument {
	id: string
	name: string
	content: string
}

interface UserDocumentsContextValue {
	getPdfDataUri: (id: string) => string | undefined
	fetchDocumentsList: () => Promise<UserDocumentsContextValue['documentsList'] | void>
	documentsList: { id: string; name: string; required?: boolean; description?: string }[]
}

const UserDocumentsContext = createContext<UserDocumentsContextValue | undefined>(undefined)

export const useUserDocuments = () => useContext(UserDocumentsContext)

export const UserDocumentsProvider = ({ children }): JSX.Element => {
	const [documentMap, setDocumentMap] = useState<{
		[key: string]: string
	}>({})
	const [loadingIdList, setLoadingIdList] = useState<Array<string>>([])
	const [documentsList, setDocumentsList] = useState<{ id: string; name: string }[]>([])

	const fetchDocumentsList = useCallback(async (): Promise<
		UserDocumentsContextValue['documentsList'] | void
	> => {
		try {
			const data = await httpService.get<{ id: string; name: string }[]>(apiUrls.esia.documents())

			setDocumentsList(data)
			return data
		} catch (error) {
			console.dir(error)
		}
	}, [])

	const loadPdfDataUri = useCallback(async (id: string) => {
		setLoadingIdList((list) => [...list, id])

		try {
			const { content } = await httpService.get<UserDocument>(apiUrls.esia.document(id))
			const byteCharacters = atob(content)
			const byteNumbers = new Array(byteCharacters.length)

			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i)
			}

			const byteArray = new Uint8Array(byteNumbers)
			const file = new Blob([byteArray], { type: 'application/pdf;base64' })
			const fileUri = URL.createObjectURL(file)

			setDocumentMap((map) => ({
				...map,
				[id]: fileUri,
			}))

			setLoadingIdList((list) => list.filter((loadingId) => loadingId !== id))
		} catch (error) {
			console.dir(error)
		}
	}, [])

	const getPdfDataUri = useCallback<UserDocumentsContextValue['getPdfDataUri']>(
		(id: string) => {
			if (loadingIdList.includes(id)) {
				return undefined
			}
			if (documentMap[id]) {
				return documentMap[id]
			}
			loadPdfDataUri(id)
			return undefined
		},
		[documentMap, loadPdfDataUri, loadingIdList]
	)

	const value = useMemo<UserDocumentsContextValue>(
		() => ({
			getPdfDataUri,
			fetchDocumentsList,
			documentsList,
		}),
		[getPdfDataUri, documentsList, fetchDocumentsList]
	)
	return <UserDocumentsContext.Provider value={value}>{children}</UserDocumentsContext.Provider>
}
