import { useEffect, useState } from 'react'
import { Sizes } from '@constants/sizes'
import { useUserAgent } from '@providers/user-agent'
import { useWindowSize } from '@shared/hooks/use-window-size'
import { isClient } from '@shared/utils/is-client'

export interface ScreensizeParams {
	isLargeDesktop: boolean
	isDesktop: boolean
	isTablet: boolean
	isMobile: boolean
	isExtraMobile: boolean
}

const getDevice = (width: number): ScreensizeParams => ({
	isLargeDesktop: width >= Sizes.desktop,
	isDesktop: width >= Sizes.tablet,
	isTablet: width < Sizes.tablet && width >= Sizes.mobile,
	isMobile: width < Sizes.mobile && width >= Sizes.extraMobile,
	isExtraMobile: width < Sizes.extraMobile,
})

export const useScreenSize = (): ScreensizeParams => {
	const { width } = useWindowSize()
	const [screenSize, setScreenSize] = useState(getDevice(width!))
	const { isMobile, isTablet, isDesktop } = useUserAgent()!

	useEffect(() => {
		setScreenSize(getDevice(width!))
	}, [width])

	if (!isClient) {
		return {
			isLargeDesktop: isDesktop,
			isDesktop,
			isTablet,
			isMobile,
			isExtraMobile: isMobile,
		}
	}

	return screenSize
}
