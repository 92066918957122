import { HttpBaseService } from '@http/service/base-service'
import { getConfigProperty } from '@shared/pipes/config'
import axios from 'axios'

export class HttpService extends HttpBaseService {}

const axiosInstance = axios.create({
	baseURL: getConfigProperty('API_URL'),
})

export const httpService: HttpService = new HttpService(axiosInstance)
