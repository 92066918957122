import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef } from 'react'
import { nanoid } from 'nanoid'

import { InputProps } from '../model'

import { View } from './view'

export const Input = ({ transformValue, ...props }: PropsWithChildren<InputProps>) => {
	const ref = useRef<HTMLInputElement>(null!)
	const inputRef = typeof props.inputRef !== 'undefined' ? props.inputRef : ref
	const inputId = useMemo(() => props.id || nanoid(5), [props.id])

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			if (transformValue) {
				event.target.value = transformValue(event.target.value)
			}
			props.onChange?.(event)
		},
		[transformValue, props.onChange]
	)

	useEffect(() => {
		if (inputRef.current && props.autoFocus) {
			inputRef.current.focus()
		}
	}, [props.autoFocus])

	return View({ ...props, inputRef, id: inputId, onChange: handleChange })
}
