import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { usePageSettings } from '@providers/page-settings'
import axios from 'axios'
import { useRouter } from 'next/router'

import { City, CITY_COOKIE_KEY, DEFAULT_CITY, regions } from '../list'
import { getAppliedCityFromCookies, getDetectedCityFromCookies } from '../utils'

import { RegionPicker } from './region-picker'

interface ContextProps {
	setCity(newCity: string): void

	openPicker(): void

	currentCity: City

	cityFromUrl: string
}

interface ProviderProps {
	newLayoytV2?: boolean
}

const regionOptions = Object.keys(regions)
	.map((alias) => ({
		value: alias,
		label: regions[alias]?.displayName,
	}))
	.sort((a, b) => {
		const textA = a.label.toUpperCase()
		const textB = b.label.toUpperCase()
		return textA < textB ? -1 : textA > textB ? 1 : 0
	})

const Context = React.createContext<ContextProps>(undefined!)

export const RegionsProvider = ({ children }: PropsWithChildren<ProviderProps>) => {
	const [cookies, setCookies] = useCookies()
	const pageSettings = usePageSettings()
	const [regionalPages, setRegionalPages] = useState<string[]>([])
	const [isVisiblePicker, setPickerVisibleStatus] = useState(false)
	const [filteredRegionOptions, setFilteredRegionOptions] = useState(regionOptions)

	const router = useRouter()

	const currentCity = useMemo(() => {
		// todos(olenin) resolve all cases in server side
		if (pageSettings.region?.contextCity) {
			return pageSettings.region?.contextCity
		}

		const appliedCity = getAppliedCityFromCookies(cookies)
		if (appliedCity) return appliedCity

		const detectedCity = getDetectedCityFromCookies(cookies)
		if (detectedCity) return detectedCity

		return DEFAULT_CITY
	}, [pageSettings, cookies])

	const applyCity = useCallback(
		(city: string, reloadPage: boolean) => {
			const date = new Date()
			date.setFullYear(2055)
			setCookies(CITY_COOKIE_KEY, city, { path: '/', expires: date })
			const pathnameWithoutCity = location.pathname
				.replace(`${currentCity}/`, '')
				.replace(`${currentCity}`, '')

			let newUrl = city
			if (regionalPages.includes(pathnameWithoutCity)) {
				newUrl += pathnameWithoutCity
			}

			if (reloadPage) window.location.pathname = newUrl
		},
		[setCookies, regionalPages]
	)

	const contextValue: ContextProps = {
		get currentCity(): City {
			return regions[currentCity]
		},
		get cityFromUrl(): string {
			return router.query.city as string
		},
		openPicker() {
			setPickerVisibleStatus(true)
		},
		setCity(newCity: string) {
			if (cookies[CITY_COOKIE_KEY] === newCity) {
				return
			}

			applyCity(newCity, true)
		},
	}

	const handleHidePicker = useCallback(() => {
		setPickerVisibleStatus(false)
	}, [setPickerVisibleStatus])

	const handleApplyFilterRegions = useCallback(
		(query: string) => {
			if (query.length === 0) {
				setFilteredRegionOptions(regionOptions)
				return
			}

			setFilteredRegionOptions(
				regionOptions.filter((option) => option.label.toUpperCase().includes(query.toUpperCase()))
			)
		},
		[setFilteredRegionOptions]
	)

	const checkActiveOption = useCallback((alias: string) => {
		return contextValue.currentCity.key === alias
	}, [])

	useEffect(() => {
		const getAdminPagesData = async () =>
			await axios
				.get('https://admin.credit.club/v1/page/sitemap-data')
				.then(async ({ data: pages }) => {
					const regionalPages = pages.filter((page) => page.isRegional).map((page) => page.url)

					setRegionalPages(regionalPages)
				})
				.catch((error) => {
					console.dir(error)
				})

		getAdminPagesData()
	}, [])

	return (
		<Context.Provider value={contextValue}>
			<RegionPicker
				isVisible={isVisiblePicker}
				onClose={handleHidePicker}
				onSelectCity={contextValue.setCity}
				options={filteredRegionOptions}
				onFilterApply={handleApplyFilterRegions}
				activeOptionResolver={checkActiveOption}
			/>
			{children}
		</Context.Provider>
	)
}

export const useRegions = () => React.useContext<ContextProps>(Context)
