import React, { PropsWithChildren } from 'react'
import cx from 'classnames'

import { IStateProps } from './model'

import styles from './style.module.scss'

export const View = ({
	children,
	className,
	tag = 'div',
	...restHtmlProps
}: PropsWithChildren<IStateProps>) =>
	React.createElement(tag, { className: cx(styles.wrapper, className), ...restHtmlProps }, children)
