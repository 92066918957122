import { isProduction } from '@shared/pipes/config'
import { isClient } from '@shared/utils/is-client'

export const emptyFn = () => undefined

export const withCheckClient = <T extends (...args: any[]) => any>(fn: T): T => {
	if (isClient) {
		return fn
	}

	return emptyFn as unknown as T
}

export const runOnClient = (fn: () => void): void => {
	if (isClient) {
		fn()
	}
}

export const runOnProd = (fn: () => void): void => {
	if (isProduction) {
		fn()
	}
}
