import { useEffect, useState } from 'react'
import { isClient } from '@shared/utils/is-client'

const getSize = () => {
	return {
		width: isClient ? window.innerWidth : undefined,
		height: isClient ? window.innerWidth : undefined,
	}
}

export const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState(getSize())

	useEffect(() => {
		if (!isClient) {
			return undefined
		}

		const handleResize = () => {
			setWindowSize(getSize())
		}

		handleResize()
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	if (!isClient) {
		return {
			height: 0,
			width: 1400,
		}
	}

	return windowSize
}
