export const apiUrls = {
	signatures(key) {
		return `/v1/site/signatures/${key}`
	},
	/**
	 * @deprecated since 23.01.2021
	 */
	createLead() {
		return '/v1/site/leads'
	},
	addOrUpdateLead: () => '/v1/site/leads',
	receiveConsentTypes: () => '/v1/site/consent-types',
	createLoanCalculate: () => 'v1/calculations/payments',
	consentTypePreview: (type: string) => `/v1/site/consents/${type}`,
	sendBrokerInfo: () => '/v1/site/brokers',
	addInvestor: () => '/v1/site/investors',
	getUser: () => '/v1/site/profile',
	getReferrerInfo: (id: string) => `/v1/site/referrers/${id}`,
	sendSmsCodeForConsentSign: () => '/v1/site/consents/sign',
	sendSlackMessageToManager: () => '/v1/site/estate-agents',
	confirmConsentSignSmsCode: (code: string) => `/v1/site/consents/verify?code=${code}`,
	dadataSuggestionsAddress: () => '/dadata/address',
	depositeTypes: (query: string) => `v1/site/deposit-types?${query}`,
	lead: {
		get: () => '/v1/site/lead',
		create: () => '/v1/site/lead',
		update: () => '/v1/site/lead',
	},
	investmentLeads: () => '/v1/site/investment-leads',
	sms: {
		send: (phone: string, captcha_token?: string) =>
			[
				`/v1/site/sms/code?phone=${phone}`,
				captcha_token ? `&captcha_token=${captcha_token}` : '',
			].join(''),
		confirm: (code: string) => `/v1/site/sms/code/verify?code=${code}`,
	},
	externalDadataSuggestionsFio: () =>
		`https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio`,
	esia: {
		test: () => '/v1/site/esia/test/auth',
		logout: () => '/v1/site/logout',
		auth: () => '/v1/site/esia/auth',
		uiConfig: () => '/v1/site/esia/documents/ui-config',
		documents: () => '/v1/site/esia/documents',
		document: (id: string) => `/v1/site/esia/documents/${id}`,
		verifySMSCode: (code: string, optionalDocumentId?: string[]) =>
			[
				`/v1/site/esia/documents/verify?code=${code}`,
				!!optionalDocumentId?.length && `&optionalDocumentId=${optionalDocumentId.join(',')}`,
			]
				.filter(Boolean)
				.join(''),
		getSignConfig: () => '/v1/site/esia/documents/sign',
	},
	getAdminPages: () => '/page/list',
	getSummaries: () => '/v1/summaries/pledges',
	getMapPledgeList: () => '/v1/maps/pledges?bbox=%b',
	getFinalDecision: () => '/v1/site/application-final-decision',
	savingCertificatePurchase: () => '/v1/site/investment/saving-certificate-purchase-application',
	savingCertificateActivation: () =>
		'/v1/site/investment/saving-certificate-activation-application',
}
