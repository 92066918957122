import { isClient } from '@shared/utils/is-client'
import log from 'console.pretty'

export const isDebug = () => {
	if (!isClient) {
		return false
	}
	return document.location.search.includes('debug=1')
}

export const isYmDebug = () => {
	if (!isClient) {
		return false
	}
	return document.location.search.includes('_ym_debug=1')
}

export const consoleDebug = (...args: any[]) => {
	runOnlyDebug(() => {
		log.green(...args)
	})
}

export const runOnlyDebug = (fn: () => void) => {
	if (isDebug()) {
		fn()
	}
}
