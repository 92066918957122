import 'focus-visible'

import React, { ComponentType, PropsWithChildren, useEffect, useMemo } from 'react'
import { CookiesProvider } from 'react-cookie'
import { HEADER_HEIGHT } from '@constants/layout'
import { StyledThemeProvider } from '@features/styled'
import { UserDocumentsProvider } from '@features/userDocuments'
import { PageSettings } from '@http/api-models/page-settings'
import { HttpIndicator } from '@http/indicator'
import { ThemeProvider } from '@material-ui/styles'
import { PageSettingsProvider } from '@providers/page-settings'
import { UserAgentProvider } from '@providers/user-agent'
import { WhatsUpActionsProvider } from '@providers/whats-app-actions'
// import * as Sentry from '@sentry/node'
import { Head } from '@shared/components/head'
import { ModalBackdrop } from '@shared/components/modal'
import { NoticeContainer } from '@shared/components/noticeContainer'
import { useEventListener } from '@shared/hooks/use-event-listener'
import { NextPageFC } from '@shared/models/next-fc'
import { initApp } from '@shared/pipes/init-app'
import scrollToElement from '@shared/pipes/scroll'
import { customizedMuiTheme } from '@styles/mui-theme'
import { parse, serialize } from 'cookie'
import { AnimateSharedLayout } from 'framer-motion'
import { nanoid } from 'nanoid'
import { NextPageContext } from 'next'
import type { AppProps } from 'next/app'
import { useUserAgent } from 'next-useragent'

import { CITY_DETECTED_COOKIE_KEY, DEFAULT_CITY } from '../regions/list'
import { RegionsProvider } from '../regions/provider'
import { getAppliedCityFromCookies, getDetectedCityFromCookies } from '../regions/utils'

import 'styles/normalize.css'
import 'styles/main.scss'
import 'react-toastify/dist/ReactToastify.css'
import { GlobalCtxProvider } from '@providers/global-context'

interface PageProps extends AppProps<{ pageSettings?: PageSettings }> {
	Component: NextPageFC<any, any>
	userAgent: any
	err: any
}

try {
	initApp()
} catch (err) {
	console.error(err)
	// Sentry.captureException(err)
}

const handleFocus = (e: React.FocusEvent<HTMLElement>) => {
	const MARGIN_TOP = 40
	const belongsHeader = e.target.closest('header')
	const belongsPopupMenu = e.target.closest('#popup-menu')

	const inputIsHidden = e.target.getBoundingClientRect().y < HEADER_HEIGHT
	let targetSelector: string

	if (belongsHeader || belongsPopupMenu || !inputIsHidden) return

	if (e.target.id) {
		targetSelector = `#${e.target.id}`
	} else {
		const generateId = nanoid(5)

		e.target.dataset.focus = generateId
		targetSelector = `[data-focus='${generateId}']`
	}

	scrollToElement(targetSelector, {
		align: {
			topOffset: HEADER_HEIGHT + MARGIN_TOP,
		},
	})
}

const DefaultLayout = ({ children }: PropsWithChildren) => <>{children}</>

const createLayout = (
	ComponentConfig: NextPageFC<any, any>,
	pageProps: any
): [ComponentType<any>, Record<any, any>] => {
	if (ComponentConfig.layout) {
		return [ComponentConfig.layout, { ...ComponentConfig.layoutProps, pageProps }]
	}

	return [DefaultLayout, ComponentConfig.layoutProps || {}]
}

const App = ({ pageProps, userAgent, Component, err }: PageProps) => {
	const headProps =
		typeof Component.headProps === 'function' ? Component.headProps(pageProps) : Component.headProps
	// https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
	const [Layout, layoutProps] = useMemo(
		() => createLayout(Component, pageProps),
		[Component, pageProps]
	)
	const newLayoytV2 = layoutProps.layoytVersion === 'version-2'

	useEffect(() => {
		// Remove the server-side injected CSS.
		const jssStyles = document.querySelector('#jss-server-side')

		if (jssStyles) {
			jssStyles.parentElement?.removeChild(jssStyles)
		}
	}, [])

	// Animate scroll to focused elements if this not viewport
	useEventListener('focusin', handleFocus)

	return (
		<GlobalCtxProvider>
			<UserAgentProvider userAgent={userAgent}>
				<PageSettingsProvider pageSettings={pageProps?.pageSettings}>
					<CookiesProvider>
						<RegionsProvider newLayoytV2={newLayoytV2}>
							<NoticeContainer />
							<Head {...headProps} />
							<HttpIndicator />
							<StyledThemeProvider>
								<ThemeProvider theme={customizedMuiTheme}>
									<UserDocumentsProvider>
										<WhatsUpActionsProvider>
											<Layout {...layoutProps}>
												<AnimateSharedLayout>
													<Component {...pageProps} err={err} />
												</AnimateSharedLayout>
											</Layout>
										</WhatsUpActionsProvider>
									</UserDocumentsProvider>
								</ThemeProvider>
							</StyledThemeProvider>
						</RegionsProvider>
					</CookiesProvider>
					<ModalBackdrop />
				</PageSettingsProvider>
			</UserAgentProvider>
		</GlobalCtxProvider>
	)
}

App.getInitialProps = ({ ctx }: { ctx: NextPageContext }) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const userAgent = ctx.req ? useUserAgent(ctx.req.headers['user-agent']!) : undefined

	/**
	 * Если пользователь переходит на не региональную страницу без определённого города
	 * назначаем стандартный. Необходимо для рабочих редиректов.
	 */
	const cookies = parse(ctx.req?.headers?.cookie || '')
	const appliedCity = getAppliedCityFromCookies(cookies)
	const detectedCity = getDetectedCityFromCookies(cookies)
	if (!appliedCity && !detectedCity) {
		ctx.res?.setHeader(
			'Set-Cookie',
			serialize(CITY_DETECTED_COOKIE_KEY, DEFAULT_CITY, { path: '/' })
		)
	}

	return {
		userAgent,
	}
}

export default App
