import { createTheme } from '@material-ui/core'

export const customizedMuiTheme = createTheme({
	typography: {
		body1: {
			fontSize: 14,
			fontWeight: 500,
			lineHeight: '20px',

			'@media (min-width: 768px)': {
				fontSize: 20,
				lineHeight: '28px',
			},
		},
	},
})
