import { emptyFn, runOnProd } from '@shared/pipes/client'
import { isProduction } from '@shared/pipes/config'
import { consoleDebug, isDebug, isYmDebug } from '@shared/pipes/is-debug'
import { isClient } from '@shared/utils/is-client'

interface SubParams {
	callback?: () => void
	ctx?: any
	params?: {
		order_price?: number
		currency?: string
	}
	referer?: string
	title?: string
}

interface YaCounterInterface {
	addFileExtension(vals: string | Array<string>): void

	extLink(url: string, options?: SubParams): void

	file(url: string, options?: SubParams): void

	getClientID(): string | undefined

	hit(url: string, options?: SubParams): void

	notBounce(options?: { callback: () => void; ctx?: any }): void

	params(parameters: Array<any> | { [key: string]: any }): void

	reachGoal(
		target: string,
		params?: { order_price?: number; currency?: string },
		callback?: () => void,
		ctx?: any
	): void

	replacePhones(): void

	setUserID(userID: string): void

	userParams(parameters: { [key: string]: any }): void

	isFake: boolean | undefined
}

const getYaCounter = (): YaCounterInterface | null => {
	const candidates = Object.keys(window).filter((k) => k.includes('yaCounter'))
	if (candidates.length === 0) {
		consoleDebug('[getYaCounter] - yaCounter not found')
		return null
	}

	if (candidates.length > 1) {
		consoleDebug('[getYaCounter] - multiple counters found', candidates)
	}

	consoleDebug('[getYaCounter] - ready, current counter', candidates[0])
	return window[candidates[0]]
}

interface CounterUtilsParams {
	ignoreCounterStubsWhileDebug: (keyof YaCounterInterface)[]
}

const fakeCounter = new Proxy(
	{ isFake: true },
	{
		get(_, p: PropertyKey) {
			return (...args) => {
				consoleDebug(`[FAKE yaCounter] - prevented run ${p.toString()} util with params`, args)
				return undefined
			}
		},
	}
) as YaCounterInterface

const createYaCounterUtils = (params: CounterUtilsParams): YaCounterInterface => {
	if (!isClient || !isProduction) {
		return fakeCounter
	}

	const counter = getYaCounter()
	if (!counter) {
		runOnProd(() => {
			console.warn('yaCounter not found')
		})
		return fakeCounter
	}

	return new Proxy(counter, {
		get(target: YaCounterInterface, p: PropertyKey, receiver: any): any {
			consoleDebug(`[yaCounter] - get ${p.toString()}`)
			if (typeof target[p] === 'function') {
				return function (...args) {
					consoleDebug(`[yaCounter] - run ${p.toString()} with arguments`, args)

					if (
						!isYmDebug() &&
						isDebug() &&
						!params.ignoreCounterStubsWhileDebug.includes(p.toString() as any)
					) {
						consoleDebug(`[yaCounter] - prevented run ${p.toString()} with arguments`, args)
						return emptyFn
					}
					return target[p](...args)
				}
			}

			return Reflect.get(target, p, receiver)
		},
	})
}

class YaCounter {
	private _utils: YaCounterInterface = fakeCounter
	updateUtils(): void {
		this._utils = createYaCounterUtils({
			ignoreCounterStubsWhileDebug: ['getClientID'],
		})
	}
	get utils(): YaCounterInterface {
		if (this._utils.isFake) {
			this.updateUtils()
		}
		return this._utils
	}
}

export const yaCounter = new YaCounter()
