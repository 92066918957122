import React, { PropsWithChildren } from 'react'

import { Props } from './model'

const UserAgentContext = React.createContext(undefined)

export const UserAgentProvider = ({ userAgent, children }: PropsWithChildren<Props>) => {
	return <UserAgentContext.Provider value={userAgent || {}}>{children}</UserAgentContext.Provider>
}

export const useUserAgent = () => React.useContext(UserAgentContext)
