export enum Paths {
	root = '/',
	personal = '/personal',
	partners = '/partners',
	platform = '/platform',
	investors = '/investors',
	invest = '/invest',
	investorsFond = '/investors/fond',
	investorsBusiness = '/investors/business',
	about = '/about',
	notFound = '/404',
	documents = '/documents',
	check = '/check',
	contacts = '/contacts',
	agreement = '/agreement',
	productRealEstate = '/pod-zalog-nedvizhimosti',
	productApartment = '/pod-zalog-kvartiry',
	productHouse = '/pod-zalog-doma',
	productRoom = '/pod-zalog-komnaty',
	productNonResidential = '/pod-zalog-kommercheskoy-nedvizhimosti',
	productShareOfRealEstate = '/pod-zalog-doli',
	signature = '/check',
	personalNaZhile = '/personal/na-zhile',
	personalNaObrazovanie = '/personal/na-obrazovanie',
	personalNaRemont = '/personal/na-remont',
	personalNaStroitelstvo = '/personal/na-stroitelstvo',
	personalNaPokupkuDoma = '/personal/na-pokupku-doma',
	personalNaRefinansirovanie = '/personal/na-refinansirovanie',
	referralPartners = '/referral-partners',
	sitemap = '/sitemap',
}

export enum ExternalPaths {
	workWithUs = 'https://ekaterinburg.hh.ru/employer/2772682',
	companyDzen = 'https://dzen.ru/procent_creditclub',
	testInvestorLk = 'https://test.lk.credit.club',
	testPartnerLk = 'https://test.partner.credit.club',
	testPersonalLk = 'https://test.lk.credit.club',
	testLenderLk = 'https://test.lender.credit.club',
	lenderLk = 'https://lender.credit.club',
	investorLk = 'https://lk.credit.club/?_ga=2.100996994.2078448377.1673935217-1726044434.1672566579',
	partnerLk = 'https://partner.credit.club/?_ga=2.255849227.2078448377.1673935217-1726044434.1672566579',
	personalLk = 'https://lk.credit.club/?_ga=2.86897819.2078448377.1673935217-1726044434.1672566579',
	loanfund = 'https://loanfund.ru',
	investTelegramBot = 'https://t.me/assistantccbot',
	investTelegramChat = 'https://t.me/invest_creditclub',
	savingsVideo = 'https://www.youtube.com/watch?v=PgdnGHu8S3g&list=PLS-PAmUui3c0B-0Hh4Xs96jS90FgBaVFk',
	savingsPresentation = 'https://storage.yandexcloud.net/creditclub-frontend/static/docs/kpk_deposits/deposits_programms.pdf',
	mk = 'https://loanstore.ru',
	skolkovo = 'https://sk.ru/',
	dvb = 'https://www.dvbank.ru/',
	companyFacebookPage = 'https://www.facebook.com/creditclub24',
	companyVkPage = 'https://vk.com/credit.club',
	companyOdPage = 'https://ok.ru/group/55909523587255',
	companyInstagram = 'https://www.instagram.com/credit.club.live',
	companyYoutubeChannel = 'https://www.youtube.com/channel/UCmSKC-7TCBJEQla4Mfo-Gzg/videos',
	companyJournal = 'https://percent.credit.club',
	companyPresentation = 'https://storage.yandexcloud.net/creditclub-frontend/static/docs/%D0%9F%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D0%B8/Credit.Club%20%E2%80%94%20%D0%BF%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F%20%D0%B4%D0%BB%D1%8F%20%D0%BA%D1%80%D0%B5%D0%B4%D0%B8%D1%82%D0%BD%D1%8B%D1%85%20%D0%B1%D1%80%D0%BE%D0%BA%D0%B5%D1%80%D0%BE%D0%B2.pdf',
	banks = 'https://creditclub.tech/',
	chronicle = 'https://www.notion.so/creditclub/Credit-Club-6c29194235a047d2a12816d0372be77d',
	career = 'https://creditclub.notion.site/Credit-Club-75f9f691cacf42b6829dfac869883632',
	cbRf = 'https://www.cbr.ru/',
	appStore = 'https://apps.apple.com/ru/app/credit-club-%D0%B4%D0%B5%D0%BD%D1%8C%D0%B3%D0%B8/id1594827999',
	playMarket = 'https://play.google.com/store/apps/details?id=soft.eac.creditclubclient',
	applicationConfirmAgreement = 'https://storage.yandexcloud.net/creditclub-frontend/static/docs/Форма%20заявки/14.12.2021/Пользовательское_соглашение_статичное.pdf',
}
