import TagManager from 'react-gtm-module'
// import * as Sentry from '@sentry/node'
import { runOnClient } from '@shared/pipes/client'
import { getConfigProperty } from '@shared/pipes/config'
import { consoleDebug, runOnlyDebug } from '@shared/pipes/is-debug'
import { getMarketingData } from '@shared/pipes/marketing-data'
import { yaCounter } from '@shared/pipes/ya-counter'

export const initApp = () => {
	// Sentry.init({
	// 	enabled: getConfigProperty('MODE') !== 'development',
	// 	dsn: getConfigProperty('SENTRY_DSN'),
	// 	environment: getConfigProperty('MODE'),
	// 	release: getConfigProperty('UI_VERSION'),
	// 	ignoreErrors: ['Non-Error exception captured', 'Request aborted'],
	// })

	/**
	 * DEBUG Monitor
	 */
	runOnlyDebug(() => {
		consoleDebug('DEBUG MODE')
		consoleDebug(`current app mode: ${getConfigProperty('MODE')}`)
		consoleDebug(`current app version: ${getConfigProperty('UI_VERSION')}`)
	})

	/**
	 * GTM
	 */
	runOnClient(() => {
		if (window.__env__.ENABLE_GTM === 'true' && window.__env__.GTM_ID) {
			const gtmId = window.__env__.GTM_ID
			consoleDebug(`[tag manager] - initialize  ${gtmId}`)

			TagManager.initialize({ gtmId })
		}
	})

	/**
	 * Fill ui get info cb
	 */
	runOnClient(() => {
		window['get_app_info'] = () => ({
			ui_version: window.__env__.UI_VERSION,
			admin_endpoint: window.__env__.API_ADMIN_URL,
		})
	})

	/**
	 * Marketing data init
	 */
	runOnClient(() => {
		window.addEventListener('load', () => {
			yaCounter.updateUtils()
			// Sentry.configureScope((scope) => {
			// 	scope.setContext('marketingData', getMarketingData() as any)
			// })
		})
	})
}
