import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import type { Nullable } from '@shared/models'
import { mergeDeepRight } from 'rambda'
import type { NullableDeep } from 'ts-toolbelt/out/Object/Nullable'

const globalCtx = React.createContext<GlobalCtxProvider>(null!)

interface GlobalCtxValue {
	user: {
		phone: Nullable<string>
	}
}

interface GlobalCtxProvider {
	globalCtx: GlobalCtxValue
	setGlobalCtx: (value: NullableDeep<GlobalCtxValue>) => void
}

export const GlobalCtxProvider = ({ children }: PropsWithChildren) => {
	const [value, setValue] = useState<GlobalCtxValue>(() => ({ user: { phone: null } }))

	const handleSetValue = useCallback(
		(v: NullableDeep<GlobalCtxValue>) => setValue((p) => mergeDeepRight(p, v) as GlobalCtxValue),
		[]
	)

	const providerValue = useMemo<GlobalCtxProvider>(
		() => ({
			setGlobalCtx: handleSetValue,
			globalCtx: value,
		}),
		[value]
	)

	return <globalCtx.Provider value={providerValue}>{children}</globalCtx.Provider>
}

export const useGlobalCtx = () => React.useContext(globalCtx)
