import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react'
import { Paths } from '@shared/menu-items/paths'
import { useRouter } from 'next/router'

import { WhatsAppActionsComponent } from './whats-app-actions-components'

export interface WhatsAppContext {
	isWhatsAppShow: boolean
	setWhatsAppShow: (val: boolean) => void
}

const visibleOnPages: string[] = [
	'/',
	Paths.personal,
	Paths.partners,
	Paths.about,
	'/loan',
	'/loan-refinance',
	'/mfo-refinance',
]

const Context = createContext<WhatsAppContext | undefined>(undefined)

export const WhatsUpActionsProvider = ({ children }: PropsWithChildren) => {
	const [isWhatsAppShow, setWhatsAppShow] = useState(true)
	const { route } = useRouter()

	const value = useMemo(() => ({ isWhatsAppShow, setWhatsAppShow }), [isWhatsAppShow])

	return (
		<Context.Provider value={value}>
			{children}
			{visibleOnPages.includes(route) && (
				<WhatsAppActionsComponent isWhatsAppShow={isWhatsAppShow} />
			)}
		</Context.Provider>
	)
}

export const useWhatsUpActions = () => useContext(Context) as WhatsAppContext
