import * as React from 'react'
import { ToastContainer } from 'react-toastify'
import { useWindowSize } from '@shared/hooks/use-window-size'

import styles from './NoticeContainer.module.scss'

export const NoticeContainer = () => {
	const { width } = useWindowSize()

	const props: React.ComponentProps<typeof ToastContainer> = {
		position: width! > 992 ? 'bottom-left' : 'top-left',
		hideProgressBar: true,
		newestOnTop: width! > 992,
		closeOnClick: true,
		limit: width! > 992 ? 3 : 1,
		className: styles.container,
	}

	return <ToastContainer {...props} />
}
