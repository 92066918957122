import { City } from '../list'

interface ExtraVars {
	[key: string]: string
}

/**
 * Возвращает новый обьект с изменёнными ключами городов на текущий контекст
 * @param city
 * @param toReplace
 * @params extraVars, example {"%var_1%": "значение переменной"}
 */
// eslint-disable-next-line
export const replaceDynamicVars = <T extends object>(
	city: City,
	toReplace: T,
	extraVars?: ExtraVars
): T => {
	const vars: [RegExp, any][] = [
		[/(%YYYY%)/g, new Date().getFullYear()],
		[/(%n%)/g, '\xA0'],
		[
			/(%city_(\w+)%)/g,
			(_, __, grammer) => {
				if (!city.grammems[grammer]) {
					console.warn(`Unknown grammer ${grammer}`)
					return grammer
				}

				return city.grammems[grammer]
			},
		],
	]

	if (extraVars) {
		Object.keys(extraVars).forEach((key) => {
			vars.push([new RegExp(key), extraVars[key]])
		})
	}

	const copy = { ...toReplace }
	Object.keys(copy).forEach((key) => {
		if (!copy[key] || typeof copy[key] !== 'string') {
			return
		}

		vars.forEach(([regExp, valOrReplacer]) => {
			if (regExp.test(copy[key])) {
				copy[key] = copy[key].replace(regExp, valOrReplacer)
			}
		})
	})

	return copy
}
