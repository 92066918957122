import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Container } from '@shared/components/container'
import { Input } from '@shared/components/text-field/input'
import { useOnClickOutside } from '@shared/hooks/use-outside-click'
import CrossIconSVG from '@shared/svg/cross.svg'
import cx from 'classnames'

import styles from './style.module.scss'

interface Props {
	isVisible: boolean

	options: {
		label: string
		value: string
	}[]

	onClose(): void

	onSelectCity(city: string): void

	onFilterApply(query: string): void

	activeOptionResolver(alias: string): boolean
}

export const RegionPicker = ({
	isVisible,
	onSelectCity,
	onClose,
	options,
	onFilterApply,
	activeOptionResolver,
}: Props) => {
	const [show, setShowStatus] = useState(false)
	const [query, setQuery] = useState('')
	const ref = useRef(null)
	useOnClickOutside(ref, onClose)

	useEffect(() => {
		if (!isVisible) {
			setQuery('')
		}
	}, [isVisible, setQuery])

	useEffect(() => {
		const timeout = setTimeout(() => {
			setShowStatus(isVisible)
		})

		return () => {
			clearTimeout(timeout)
		}
	}, [isVisible, setShowStatus])

	useEffect(() => {
		const timeout = setTimeout(() => {
			onFilterApply(query)
		}, 1000)

		return () => {
			clearTimeout(timeout)
		}
	}, [query, onFilterApply])

	const handleChangeInput = useCallback(
		({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
			setQuery(value)
		},
		[]
	)

	if (!isVisible) {
		return null
	}

	return (
		<div ref={ref} className={cx(styles.wrapper, show && styles.show)}>
			<Container className={styles.container}>
				<div className={styles.closeIcon} onClick={onClose}>
					<CrossIconSVG />
				</div>
				<div>
					<h1>Выберите город</h1>
					<div className={styles.hint}>
						Если вашего города нет в списке, выберите ближайший — мы попробуем найти решение.
					</div>
					<div className={styles.search}>
						<Input name={query} label='Ваш город' onChange={handleChangeInput} />
					</div>
				</div>
				{options.length === 0 ? (
					<div className={styles.empty}>Города по указанному запросу не найдены</div>
				) : (
					<ul className={styles.list}>
						{options.map(({ label, value }) => (
							<li
								onClick={() => onSelectCity(value)}
								key={value}
								className={cx(styles.option, activeOptionResolver(value) && styles.optionActive)}
							>
								{label}
							</li>
						))}
					</ul>
				)}
			</Container>
		</div>
	)
}
