const init = () => {
	return `
		(function(a) {
        var b = a.createElement("script");
        b.async = 1;
        b.src = "//code.6dp0i7.ru/";
        a = a.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(b, a)
    })(document);
	`
}

const pushEvent = (event: Record<string, any>) => {
	window.advcake_data = window.advcake_data || []

	window.advcake_data.push(event)
}

export const AdvCake = {
	init,
	pushEvent,
}
