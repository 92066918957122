import React, { PropsWithChildren, useCallback, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useEventListener } from '@shared/hooks/use-event-listener'
import { runOnClient } from '@shared/pipes/client'
import { isClient } from '@shared/utils/is-client'

import { ModalProps } from './model'
import { BodyView, HeaderView, ModalView } from './view'

const BACKDROP_ID = 'modal-backdrop'

const getFocusableElements = (target: Element) =>
	target.querySelectorAll(
		'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
	)

export const Modal = ({ show, onClose, children, size }: PropsWithChildren<ModalProps>) => {
	const overlayRef = useRef<HTMLDivElement>(null)

	/**
	 * Используется для предотвращения фокусировки элементов под модальным окном
	 * Нуждается в дополнительной настройке если модалки будут храниться в стеке
	 * @param target
	 */
	useEventListener('focusin', ({ target }: any) => {
		const root = document.getElementById('__next')

		if (
			show &&
			overlayRef.current &&
			root?.contains(target) &&
			!overlayRef.current.contains(target)
		) {
			const focusableElements = getFocusableElements(overlayRef.current)
			if (focusableElements.length) {
				;(focusableElements[0] as HTMLElement).focus()
			} else {
				if (document.activeElement) {
					;(document.activeElement as HTMLElement).blur()
				}
			}
		}
	})

	/**
	 * Используется для закрытия окна при нажатии на escape
	 * Нуждается в дополнительной настройке если модалки будут храниться в стеке
	 * @param target
	 */
	useEventListener('keyup', ({ key }) => {
		if (show && key === 'Escape') {
			handleClose()
		}
	})

	const handleClose = useCallback(() => {
		onClose && onClose()
	}, [onClose])

	const handleCrossKeyPress = useCallback(
		(e: React.KeyboardEvent<HTMLDivElement>) => {
			if (e.key === 'Enter') {
				handleClose()
			}
		},
		[handleClose]
	)

	const container = ModalView({
		children,
		overlayRef,
		size: size || 'medium',
		onCloseModal: handleClose,
		onCrossKeyPress: handleCrossKeyPress,
		show,
	})

	return isClient ? createPortal(container, document.getElementById(BACKDROP_ID)!) : container
}

Modal.Header = HeaderView
Modal.Body = BodyView

export const ModalBackdrop = () => {
	const ref = useRef<HTMLDivElement>(null)
	useEffect(() => {
		runOnClient(() => {
			const { documentElement } = document
			const observer = new MutationObserver((list) => {
				const lastMutation = list[list.length - 1]

				if (lastMutation.target.hasChildNodes()) {
					documentElement.classList.add('overflow-hidden')
				} else {
					documentElement.classList.remove('overflow-hidden')
				}
			})

			observer.observe(ref.current!, { childList: true })
		})
	})

	const container = <div ref={ref} id={BACKDROP_ID} />
	return isClient ? createPortal(container, document.body) : container
}
