import React from 'react'
import { ThemeProvider } from 'styled-components'

import { styledTheme } from './theme'

interface StyledThemeProviderProps {
	children: any
}

export function StyledThemeProvider({ children }: StyledThemeProviderProps): JSX.Element {
	return <ThemeProvider theme={styledTheme}>{children}</ThemeProvider>
}
