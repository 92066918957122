import regionsJSON from './json/regions.json'

export interface City {
	displayName: string
	key: string
	grammems: {
		// Падежи
		im: string // Именительный
		rd: string // Родительный
		dt: string // Дательный
		vn: string // Винительный
		tv: string // Творительный
		pr: string // Предложный
	}
}

export const DEFAULT_CITY = 'ekaterinburg'

export const CITY_COOKIE_KEY = 'platform.user_applied_city'
export const CITY_DETECTED_COOKIE_KEY = 'platform.user_detected_city'

export const regions: { [alias: string]: City } = regionsJSON
